import Vue from 'vue'
import Vuex from 'vuex'
import userCenter from './userCenter'
import system from './system'

Vue.use(Vuex)


export default new Vuex.Store({
    modules:{
        userCenter,
        system
    }
})
